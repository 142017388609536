$ ->
  if window.NEO.debug then window.inputNames=["selectedPrefectureId","selectedCityId","schoolNameKana","selectedSchoolSpecId"]

  #==============================
  # データモデル
  #==============================
  #*****都道府県*****
  Prefecture = (data)->
    @id = ko.observable data?.id
    @name = ko.observable data?.name
    @#
  #*****市町村*****
  City = (data)->
    @id = ko.observable data?.id
    @name = ko.observable data?.name
    @#
  #*****校種*****
  SchoolSpec = (data)->
    @id = ko.observable data?.id
    @name = ko.observable data?.name
    @#

  #==============================
  # ビューモデル
  #==============================
  PageViewModel = ()->
    #------------------------------
    # プロパティ
    #------------------------------
    #画面初期化の準備完了状態
    @isReadyInit = ko.observable(false)

    #ログイン前ヘッダー共通処理VM
    @headerVM = ko.observable(new window.BlueHeader({appDir:"signup3",headers:{}}))

    #リストア処理
    @restoreStorage = ko.computed ()=>
      ret = window.NEO.s_session.get("signup_1")
      return ret

    #都道府県UI
    @prefectures = ko.observableArray []
    @selectedPrefectureId = ko.observable("")
    ## 都道府県変更時イベント
    @selectedPrefectureId.subscribe (newValue)=>
      return "" if window.NEO.is_blank newValue
      #都道府県情報を元に市町村リストをAPIより取得する
      window.NEO.get_api(window.NEO.url.ajax_albums_prefecture_city + "#{newValue}")
      .done (res) =>
        @errors.removeAll()
        mappedTags = $.map res.data, (item)-> new City(item)
        @cities(mappedTags)
        #保存データがあれば復元
        restore = @restoreStorage()
        @selectedCityId(String(restore?.city_id || ""))
        @#
      .fail (res) =>
        if(res.responseJSON?.meta?)
          @errors([res.responseJSON?.meta?.resultmsg])
        #例外時処理
        @cities.removeAll()
        @selectedPrefectureId("")
        @#
    #市町村UI
    @selectedCityId = ko.observable("")
    @cities = ko.observableArray []
    @cityCaption = ko.computed ()=>
      if window.NEO.is_blank @selectedPrefectureId()
        "都道府県を先に選択してください"
      else
        "選択してください"
    #校種UI
    @selectedSchoolSpecId = ko.observable()
    @schoolSpecs = ko.observableArray []
    #学校名UI
    @schoolNameKana = ko.observable("")
    #入力エラー配列
    @errors = ko.observableArray []

    #学校重複時の学校ID
    @selectedduplicateShoolId = ko.observable()
    #
    @duplicateShools = ko.observableArray []

    #Ajax通信データの取得とバリデーション
    @albumAuthPosts = ko.computed ()=>
      ret =
        prefecture_id:@selectedPrefectureId() || "" #サーバーで利用していないが保存のため
        city_id:@selectedCityId() || ""
        school_spec_id:@selectedSchoolSpecId() || ""
        school_name_kana: @schoolNameKana() || ""
      ret.school_id = @selectedduplicateShoolId() if !window.NEO.is_blank(@selectedduplicateShoolId())

      return "都道府県が選択されていません" if window.NEO.is_blank ret.prefecture_id
      return "市町村が選択されていません" if window.NEO.is_blank ret.city_id
      return "校種が選択されていません" if window.NEO.is_blank ret.school_spec_id
      return "学校名が入力されていません" if window.NEO.is_blank ret.school_name_kana
      return "学校名はひらがなで入力してください" unless ret.school_name_kana.match(/^[ぁ-んー]+$/)
      return ret
    #------------------------------
    # イベント
    #------------------------------
    #前画面へ遷移
    @moveToLogin = ()=>
      location.href=window.NEO.url.page_login
      @#
    #次画面へ遷移と入力判定
    @sequeSignUp = () =>
      if window.NEO.debug then for item in window.inputNames then $("*[name='#{item}']").change()
    #アルバムの存在を確認する
      posts = @albumAuthPosts()
      @selectedduplicateShoolId(null) #データ送信後に重複学校ID選択を空にする
      (@errors([posts]); return) unless $.isPlainObject(posts) #エラーあり
      @errors.removeAll()
      window.NEO.post_api(window.NEO.url.ajax_albums_auth,posts)
      .done (res) =>
        #セッションストレージにデータを保存
        window.NEO.s_session.set({signup_1:ko.toJSON($.extend(posts,{match_pattern:res.data?.match_pattern}))})
        location.href = window.NEO.url.page_signup
      .fail (res) =>
        console.log res
        if(res.responseJSON?.meta?)
          @errors([res.responseJSON?.meta?.resultmsg])
          switch res.responseJSON?.meta?.error_code
            when "0007" # 学校情報の重複確認
              mappedData = _.map res.responseJSON?.meta?.data,(val,key)=>
                return {id:key,name:val}
              @duplicateShools(mappedData)
              window.addEventListener("showDuplicateSchoolSelectModalEvent",window.NEO.show_modal_and_scroll_patch, false)
              evt = document.createEvent("CustomEvent")
              evt.initCustomEvent("showDuplicateSchoolSelectModalEvent", true, true,1)
              evt.data = {modalId : "duplicate_school_select_modal"}
              window.dispatchEvent(evt)
            #
          #
        @#

      @#
    #初期化処理
    do =>
      $.when(window.NEO.get_api(window.NEO.url.ajax_albums_prefecture), window.NEO.get_api(window.NEO.url.ajax_albums_school_spec))
      .done (res_albums_prefecture, res_albums_school_spec) =>

        #都道府県情報の構築(!!!!@selectedPrefectureId()の値代入より先に構築すること)
        mappedTags = $.map res_albums_prefecture.data, (item)-> new Prefecture(item)
        @prefectures(mappedTags)

        #校種情報の構築(!!!!@selectedSchoolSpecId()の値代入より先に構築すること)
        mappedTags = $.map res_albums_school_spec.data, (item)-> return new SchoolSpec(item)
        @schoolSpecs(mappedTags)

        #保存データがあれば復元
        restore = @restoreStorage()
        @schoolNameKana(restore?.school_name_kana)
        @selectedPrefectureId(String(restore?.prefecture_id || ""))
        @selectedSchoolSpecId(String(restore?.school_spec_id || ""))
        #@selectedduplicateShoolId(String(restore?.school_id || ""))

        @isReadyInit(true)
        @
      .fail (res)=>
        if(res.responseJSON?.meta?)
          @errors([res.responseJSON?.meta?.resultmsg])
        @#
      @#
    @#
  #==============================
  # 画面処理
  #==============================
  #knockoutの適用
  window.vm = new PageViewModel()
  ko.applyBindings(window.vm)

  #$(".qaa_shipping_charge_modal_btn").click({modalId : "qaa_shipping_charge_modal"},)
  $('#duplicate_school_select_modal').on('hide.bs.modal', window.NEO.hide_modal_and_scroll_patch)


